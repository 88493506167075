import React from 'react'
import { NavLink } from 'react-router-dom'

const Footer = () => {
  return (
    <footer id='footer'>
      <section>
        <div className='footer-left'>
          <NavLink className='logo-footer' to={'/'}>Anders Garpelin</NavLink>
          <div className='social'>
            <a href="https://www.instagram.com/andersgarpelin/" aria-label='Följ Anders Garpelin på Instagram.' target='_blank' rel="noreferrer"><i className='fa-brands fa-instagram'></i></a>
            <a href="https://www.facebook.com/people/Anders-Garpelin/61550804157499/" aria-label='Följ Anders Garpelin på Facebook.' target='_blank' rel="noreferrer"><i className='fa-brands fa-facebook-f'></i></a>
          </div>
        </div>
        <ul>
          <li><NavLink to={'/author'}>Författaren</NavLink></li>
          <li><NavLink to={'/books'}>Skönlitteratur</NavLink></li>
          <li><NavLink to={'/chronicles'}>Krönikor</NavLink></li>
          <li><NavLink to={'/science'}>Facklitteratur</NavLink></li>
          <li><NavLink to={'/upcoming'}>Aktuellt</NavLink></li>
        </ul>  
      </section>
    </footer>
  )
}

export default Footer