import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

const Navbar = () => {
  const [open, setOpen] = useState(false);

  const openToggle = () => {
    if(open === false) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  return (
    <nav id='navbar'>
      <div className='navbar-content'>
        <div>
          <NavLink className='logo' to={'/'}>Anders Garpelin</NavLink>  
        </div>
        <button className='navbar-toggler' onClick={openToggle}>
          <div className={open ? 'open' : 'closed'}>
            <i className='fa-solid fa-bars icon-left'></i>
            <i className='fa-solid fa-xmark icon-right'></i>
            <ul className='toggle-navbar-menu'>
              <li><NavLink to={'/author'}>Författaren</NavLink></li>
              <li><NavLink to={'/books'}>Skönlitteratur</NavLink></li>
              <li><NavLink to={'/chronicles'}>Krönikor</NavLink></li>
              <li><NavLink to={'/science'}>Facklitteratur</NavLink></li>
              <li><NavLink to={'/upcoming'}>Aktuellt</NavLink></li>
            </ul>
          </div>
        </button>

        <div className='collapse'>
          <ul className='navbar-nav'>
            <li><NavLink to={'/author'}>Författaren</NavLink></li>
            <li><NavLink to={'/books'}>Skönlitteratur</NavLink></li>
            <li><NavLink to={'/chronicles'}>Krönikor</NavLink></li>
            <li><NavLink to={'/science'}>Facklitteratur</NavLink></li>
            <li><NavLink to={'/upcoming'}>Aktuellt</NavLink></li>
          </ul>
        </div>
      </div>
      
    </nav>
  )
}

export default Navbar