import React from 'react'

const Science = () => {
  return (
    <div className='science'>
      <h1>Facklitteratur</h1>
      <h2>Avhandling</h2>
      <a href='http://uu.diva-portal.org/smash/get/diva2:313913/FULLTEXT01.pdf'>
        <div className='book-container'>
          <div className='img-container'>
            <img src={require('../images/lektionen.jpg')} alt="Framsidan av avhandlingen Lektionen och livet." />
          </div>
          <div className='text-container'>
            <p>Garpelin, A. (1997)</p>
            <p><strong>Lektionen och livet.</strong></p>
            <p><em>Ett möte mellan ungdomar som tillsammans bildar en skolklass.</em></p>
            <p>Uppsala: Acta Universitatis Upsaliensis.</p>
            <p>ISBN 91-554-3934-9</p>
          </div>
        </div>
      </a>

      <h2>Böcker:</h2>
      <div className='book-container'>
        <div className='img-container'>
          <img src={require('../images/skolklassen.png')} alt="Framsidan av boken Skolklassen som ett socialt drama." />
        </div>
        <div className='text-container'>
          <p>Garpelin, A. (1998).</p>
          <p><strong>Skolklassen som ett socialt drama.</strong></p>
          <p>Lund: Studentlitteratur.</p>
          <p>ISBN 91-44-00543-1</p>
        </div>   
      </div>
      <div className='book-container'>
        <div className='img-container'>
          <img src={require('../images/ungiskolan.png')} alt="Framsidan av boken Ung i skolan." />
        </div>
        <div className='text-container'>
          <p>Garpelin, A. (2003).</p>
          <p><strong>Ung i skolan.</strong></p>
          <p><em>Om övergångar, klasskamrater, gemenskap och marginalisering.</em></p>
          <p>Lund: Studentlitteratur.</p>
          <p>ISBN 91-44-03205-6.</p>
        </div>
        
      </div> 
    </div>
  )
}

export default Science