import React from 'react';

const ChronicleItem = ({ date, title, link }) => {
// const ChronicleItem = () => {
  
  
  return (
    <section className='chronicle-item'>
      <a href={`${link}`} aria-label='Länk till krönikan.' target='_blank' rel="noreferrer">
        <small>{date}</small>
        <h3>{title}</h3>
      </a>
      {/* <small>den 6 december 2017</small>
      <h3>Så byggdes Finland</h3>
      <p>Min svärfar Mauri Kullervo Bergqvist föds i Viborg den 8 november 1916. Staden med sin borg och sin ringmur utgör under fyra århundraden en viktig utpost i östra delen av det svenska riket fram till att Viborg tillfaller Ryssland 1721. När Sverige avträder Finland till Ryssland 1809 skapas Storfurstendömet Finland, där Karelen och Viborg efter närmare hundra år återförenas med resten av Finland. Viborgs betydelse ökar åter, nu som handels- och sjöfartsstad nära huvudstaden Sankt Petersburg. Staden har en internationell prägel och på gatorna hörs omväxlande finska, svenska, ryska och tyska.</p>
      <p>Under Mauris första levnadsår är det orostider i Ryssland med tsarväldets störtande och oktoberrevolution följt av fyra års inbördeskrig. Den finska lantdagen utnyttjar situationen och utropar Finland som en självständig stat den 6 december 1917. Mauri blir medborgare i den nya finska nationen.</p>
      <p>Efter två månader utbryter det värsta tänkbara för det nya landet, ett blodigt inbördeskrig. Precis som i Ryssland står röda mot vita. I striderna hamnar familjemedlemmar och grannar på olika sida. De vita segrarna tar en gruvlig hämnd. Tusentals röda arkebuseras utan rättegång i ståndrätter och drygt tio tusen dör i fångläger. Övriga på den röda sidan förlorar sina medborgerliga rättigheter. Hatet mellan röda och vita lever kvar i flera decennier.</p>
      <p>Livet blomstrar i mellankrigstidens Viborg som 1939 är Finlands andra största stad med 86 000 invånare. Mörka moln tornar dock upp på himlen samma år då ryssarna går till anfall. Mauri blir inkallad för att strida mot angriparna. Vinterkriget slutar med att den ryska björnen åter lägger beslag på Viborg. Mauri förlorar genom detta sitt hem och sin hemstad. Sommaren 1941 blir han åter skickad till fronten och samma fiende. Viborg återtas och dess invånare kan flytta tillbaka. Tre år senare måste befolkningen åter evakueras.</p>
      <p>Efter 5 år som soldat blir Mauri civil i samband med att vapenvilan inleds i september 1944.  Ett villkor är att Finland omedelbart driver ut de tyska trupper som fortfarande finns kvar i den norra delen av landet. Tyskarna känner sig svikna av sina tidigare vapendragare och bränner ned stora delar av finska Lappland. Finland får i freden behålla sin självständighet tack vare skickliga förhandlare och den starka insatsen på slagfältet. Fredsvillkoren är dock hårda. Nationen tilldöms ett högt krigsskadestånd och delar av landet besätts av ryska trupper under flera år samtidigt som stora landområden förloras för all framtid, bl.a. Viborg. Krigsskadeståndet medför stora uppoffringar men samtidigt en drivkraft för industrin.</p>
      <p>Under presidenterna Paasikivi och Kekkonen (1946-1981) blir den nya linjen att i det ingångna vänskaps- och biståndsavtalets anda leva i fredlig samexistens med den tidigare fienden. Finska politikers undfallenhet mot Sovjet under denna period kritiseras idag öppet, men då glömmer kritikerna lätt hur skört det var i omvärlden under dessa år. Kuppen mot den demokratiskt valda tjeckoslovakiska regimen 1948 är bara ett exempel.</p>
      <p>Efter Sovjetunionens fall går Finland med i EU, inför euron, köper flygplan från USA och närmar sig NATO. Finland är en framgångsrik industrination med hög levnadsstandard och känd status som fredsmäklare.</p>
      <p>Min äldsta dotter berättar stolt att den bok hon översatt nu publicerats, ”Så byggdes Finland”. Den handlar om arkitektur och byggande under de 100 år Finland format sin nation. Boken är ett exempel på den kraftsamling som under hela jubileumsåret genomförts på en rad områden i vårt östra grannland. Hennes morfar skulle ha varit stolt över hur hon bidragit till att en del av Finlands historia dokumenteras.</p>
      <p>Ända till sin bortgång för sju år sen sörjde Mauri sitt kära Viborg, något han delat med övriga Viborgare och andra karelare. Från hösten 1944 hade främmande människor övertagit deras hus och odlat deras marker.</p>
      <p>Idag tänder vi två blåvita ljus för Finland!</p>
      <br />
      <p>&copy; Anders Garpelin</p> */}
    </section>
  )
}

export default ChronicleItem