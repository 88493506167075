import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './styles/App.scss';
import Home from './views/Home';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Author from './views/Author';
import Books from './views/Books';
import Chronicles from './views/Chronicles';
import Upcoming from './views/Upcoming';
import Science from './views/Science';

const App = () => (
  <Router>
    <div id="App">
      <Navbar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/author' element={<Author />} />
        <Route path='/books' element={<Books />} />
        <Route path='/chronicles' element={<Chronicles />} />
        <Route path='/upcoming' element={<Upcoming />} />
        <Route path='/science' element={<Science />} />
      </Routes>
      <Footer />
    </div>
  </Router>
)

export default App;
