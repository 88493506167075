import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCaJvqPm4V6CM39pY7OVtl-6WHGxckoHhE",
  authDomain: "ag-homepage.firebaseapp.com",
  projectId: "ag-homepage",
  storageBucket: "ag-homepage.appspot.com",
  messagingSenderId: "921037428807",
  appId: "1:921037428807:web:116711fc1966a0682d7962",
  measurementId: "G-VQB40YDC1K"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
logEvent(analytics, 'notification_recieved');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
