import React from 'react'

const Author = () => {
  return (
    <div>
      <section className='author-intro'>
        <div className='img-container'>
          <img src={require('../images/Anders_kvadrat.jpg')} alt="Bild på Anders Garpelin." />
        </div>
        <div className='author-text'>
          <h1>Anders Garpelin</h1>
          <p><em>Anders Garpelin</em> är uppvuxen i Västerås i en familj med en äldre och en yngre syster, Lena och Pia. 
            Han tillbringade många av sin barndoms somrar hos sin mormor i stationssamhället Kil och i familjens sommarstuga i västra Värmland. 
            Hans far Gösta var folkskollärare och modern Kerstin hemmafru. Under Anders högstadietid började föräldrarna att studera på universitet. </p>
          <p>Anders är utbildad vid Umeå och Uppsala universitet och har arbetat inom det psykosociala området och som lärare. 
            Efter sin disputation med avhandlingen <em>Lektionen och livet</em> har han arbetat med utbildning av socialarbetare, lärare, speciallärare och specialpedagoger samt forskarstuderande. 
            Anders, som har forskat om barns och ungas grupprocesser, utsatthet och marginalisering, är idag professor emeritus vid Mälardalens universitet. </p>
          <p>Privat har Anders tillsammans med sin hustru Merja fostrat tre barn, Milla, Matti och Maja. 
            På lämpligt avstånd följer han idag hur hans åtta barnbarn växer så det knakar. 
            En merit är att han handlett sina tre barn och ett barnbarn fram till körkort. 
            En annan är att han spelat sjukhus-SM i badminton och spelat cup-final i volleyboll på korpnivå.</p>
          <p>Att skriva skönlitteratur har fått stå tillbaka under yrkeskarriären, men som pensionär debuterar Anders nu med romanen <em>Bortvald som barn</em>. 
            Därmed är tärningen kastad! Han utlovar en fortsättning, där förutsättningar ges för att få en fördjupad förståelse av det sär-skilda liv huvudpersonen Karin lever genom livets olika passageriter. </p>
        </div>
      </section>
    </div>
  )
}

export default Author