import React, { useState } from 'react'
import ChronicleItem from '../components/ChronicleItem'

const Chronicles = () => {

  // const [items, setItems] = useState([]);

  // const fetchData = () => {
  //   return fetch('../db/list.json')
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setItems(data.items)
  //     });
  // }

  // useEffect(() => {
  //   fetchData();
  // }, []);

  // console.log(items + "chronicles")

  const [items] = useState([
    {
      id: 1,
      title: "Det behövs eftertanke i bostadspolitiken",
      date: "2018-01-05",
      link: "https://www.vlt.se/2018-01-05/anders-garpelin-det-behovs-eftertanke-i-bostadspolitiken"
    },
    {
      id: 2,
      title: "Vilka gillar förtätningsfundamentalismen?",
      date: "2018-02-09",
      link: "https://www.vlt.se/2018-02-09/anders-garpelin-vilka-gillar-fortatningsfundamentalismen"
    },
    {
      id: 3,
      title: "Studenten - vår tids passagerit till vuxenvärlden",
      date: "2018-06-16",
      link: "https://www.vlt.se/2018-06-16/anders-garpelin-studenten--var-tids-passagerit-till-vuxenvarlden"
    },
    {
      id: 4,
      title: "Stormaktsmötet i Helsingfors var ett hot mot Europas folk",
      date: "2018-07-17",
      link: "https://www.vlt.se/2018-07-17/anders-garpelin-stormaktsmotet-i-helsingfors-var-ett-hot-mot-europas-folk"
    },
    {
      id: 5,
      title: "Skogsbränder släcks bättre i Finland än i Sverige",
      date: "2018-07-28",
      link: "https://www.vlt.se/2018-07-28/anders-garpelin-skogsbrander-slacks-battre-i-finland-an-i-sverige"
    },
    {
      id: 6,
      title: "Inför tioårig grundskola från 6 års ålder",
      date: "2018-10-06",
      link: "https://www.vlt.se/2018-08-11/anders-garpelin-infor-tioarig-grundskola-fran-6-ars-alder"
    },
    {
      id: 7,
      title: "Västerås behöver ett nytt Bevarandeprogram",
      date: "2018-10-13",
      link: "https://www.vlt.se/2018-10-13/anders-garpelin-vasteras-behover-ett-nytt-bevarandeprogram"
    },
    {
      id: 8,
      title: "Superchans att stärka Västerås",
      date: "2018-10-27",
      link: "https://www.vlt.se/2018-10-27/anders-garpelin-superchans-att-starka-vasteras"
    },
    {
      id: 9,
      title: "Svenskan är garant för Finland i Norden",
      date: "2018-11-06",
      link: "https://www.vlt.se/2018-11-06/anders-garpelin-svenskan-ar-garant-for-finland-i-norden"
    },
    {
      id: 10,
      title: "Nej till containerarkitektur i Sveriges näst fulaste stad",
      date: "2018-11-17",
      link: "https://www.vlt.se/2018-11-17/nej-till-containerarkitektur-i-sveriges-nast-fulaste-stad"
    },
    {
      id: 11,
      title: "Lärarutbildningen förlorar sin själ?",
      date: "2018-12-02",
      link: "https://www.vlt.se/2018-12-02/anders-garpelin-lararutbildningen-forlorar-sin-sjal"
    },
    {
      id: 12,
      title: "Kan Varberg gräva ned spåren kan Västerås, men då krävs politisk vilja",
      date: "2018-12-15",
      link: "https://www.vlt.se/2018-12-15/anders-garpelin-kan-varberg-grava-ned-sparen-kan-vasteras-men-da-kravs-politisk-vilja"
    }
    
  ])

  // ,
    // {
    //   id: 13,
    //   title: "Titel",
    //   date: "date 2018",
    //   link: "link"
    // }

  return (
    <div className='chronicles'>
      <h1>Krönikor</h1>
      <h2>Publicerade på ledarsidan i Västmanlands Läns Tidning (VLT)</h2>
      {
        items.length ?
          items.map(item => (
            <ChronicleItem key={item.id} date={item.date} title={item.title} link={item.link} />
          ))
          : <p>Inga krönikor här</p>
      }
      <br />
      <p><em>Fler krönikor kommer snart.</em></p>
    </div>
  )
}

export default Chronicles