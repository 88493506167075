import React from 'react'

const Upcoming = () => {
  return (
    <div className='upcoming'>
      <h1>Aktuellt</h1>
      <p>Andra boken i serien om Karin, "Sårbarhet och livskraft", kommer ut i slutet av september 2024. <a href="https://www.vistoforlag.se/bok/sarbarhet-och-livskraft/">Förbeställ boken här.</a></p>
      <p><strong>2024-10-12:</strong> Västerås litteraturfestival kl. 10-15, Stadsbiblioteket, Västerås (bokbord)</p>
      <p><strong>2024-11-08:</strong> Värmlands Bokfestival kl. 10-17, Nöjesfabriken, Karlstad (scenframträdande, bokbord). <a href="https://varmlandsbokfestival.se/">Mer information här.</a></p>
      <p><strong>2024-11-09:</strong> Värmlands Bokfestival kl. 10-16, Nöjesfabriken, Karlstad (bokbord). <a href="https://varmlandsbokfestival.se/">Mer information här.</a></p>
      <p>Nu finns boken Bortvald som barn även som ljudbok på <a href="https://www.bookbeat.se/search?title=Bortvald+som+barn&page=1&query=Bortvald+som+barn" aria-label='Lyssna på boken på Bookbeat.' target='_blank' rel="noreferrer">Bookbeat</a>, <a href="https://www.storytel.com/se/books/bortvald-som-barn-2763341" aria-label='Lyssna på boken på Storytel.' target='_blank' rel="noreferrer">Storytel</a> och <a href="https://nextory.com/se/search?q=Bortvald%20Som%20Barn" aria-label='Lyssna på boken på Nextory.' target='_blank' rel="noreferrer">Nextory</a>.</p>

      <h2>Passerat:</h2>
      <p className='past'><strong>2024-03-19:</strong> Uppläsningskväll kl. 17-19 på Café Mosaic, Västerås. Västmanlands Författarsällskap (författaruppläsning)</p>
      <p className='past'><strong>2024-02-27:</strong> Uppläsningskväll kl. 17-19 på Café Mosaic, Västerås. Västmanlands Författarsällskap (författaruppläsning)</p>
      <p className='past'><strong>2023-12-19:</strong> Akademibokhandeln på Igor, Västerås, har författarbesök av Anders Garpelin som säljer och signerar sin bok Bortvald som barn</p>
      <p className='past'><strong>2023-12-14:</strong> Akademibokhandeln på Igor, Västerås, har författarbesök av Anders Garpelin som säljer och signerar sin bok Bortvald som barn</p>
      <p className='past'><strong>2023-12-07:</strong> Uppläsningskväll kl. 17-19 på Café Mosaic, scenen framför caféborden, Västerås. Västmanlands Författarsällskap (författaruppläsning)</p>
      <p className='past'><strong>2023-11-18:</strong> Värmlands Bokfestival kl. 10-16, Nöjesfabriken, Karlstad (bokbord). <a href="https://varmlandsbokfestival.se/">Mer information här</a></p>
      <p className='past'><strong>2023-11-17:</strong> Värmlands Bokfestival kl. 10-17, Nöjesfabriken, Karlstad (scenframträdande 12.30,bokbord). <a href="https://varmlandsbokfestival.se/">Mer information här</a></p>
      <p className='past'><strong>2023-11-09:</strong> Uppläsningskväll kl. 17-19 på Café Mosaic, Västerås. Västmanlands Författarsällskap (författaruppläsning)</p>
      <p className='past'><strong>2023-10-31:</strong> Författarbesök med bokpresentation, kl. 17-19, Hörsalen, Stadsbiblioteket, Västerås. <a href="https://bibliotekivastmanland.se/detaljerade-handelser/?id=15790">Mer information här</a></p>
      <p className='past'><strong>2023-10-14:</strong> Västerås litteraturfestival kl. 10-15, Stadsbiblioteket, Västerås (bokbord)</p>
      <p className='past'><strong>2023-09-26:</strong> Uppläsningskväll kl. 17-19 på Café Mosaic, Västerås. Västmanlands Författarsällskap (författaruppläsning)</p>
      <p className='past'><strong>2023-05-16:</strong> Uppläsningskväll på Café Mosaic, Västerås. Västmanlands Författarsällskap (författaruppläsning)</p>
    </div>
  )
}

export default Upcoming