import React from 'react'
import { Link } from 'react-router-dom'

const Home = () => {
  return (
    <div className='home'>
      <div className='home-section-container'>
        <section className='home-section'>
          <Link to={'/Books'}>
            <h1>Bortvald som barn</h1>
            <div className='img-container'>
              <img src={require('../images/bortvald_omslag_fram.jpg')} alt="Bild på bildomslag till boken 'Bortvald som barn'." />
            </div>
          </Link>
          <a href='https://www.vistoforlag.se/bok/bortvald-som-barn/' aria-label='Beställ boken via förlagets webshop.' target='_blank' rel="noreferrer"><h2>Beställ boken här</h2></a>
        </section>

        <section className='home-section'>
          <Link to={'/Books'}>
            <h1>Sårbarhet och livskraft</h1>
            <div className='img-container'>
              <img src={require('../images/bok2.jpg')} alt="Bild på bildomslag till boken 'Sårbarhet och livskraft'." />
            </div>
          </Link>
          <a href='https://www.vistoforlag.se/bok/sarbarhet-och-livskraft/' aria-label='Förbeställ boken via förlagets webshop.' target='_blank' rel="noreferrer"><h2>Förbeställ boken här</h2></a>
        </section>  
      </div>
      
      
      <aside className='home-upcoming'>
        <h2>Aktuellt</h2>
        <p>Andra boken i serien om Karin,<strong>"Sårbarhet och livskraft"</strong>, kommer ut i slutet av september 2024. <a href="https://www.vistoforlag.se/bok/sarbarhet-och-livskraft/">Förbeställ boken här.</a></p>
        <p><strong>2024-10-12:</strong> Västerås litteraturfestival kl. 10-15, Stadsbiblioteket, Västerås (bokbord)</p>
        <p><strong>2024-11-08:</strong> Värmlands Bokfestival kl. 10-17, Nöjesfabriken, Karlstad (scenframträdande, bokbord). <a href="https://varmlandsbokfestival.se/">Mer information här.</a></p>
        <p><strong>2024-11-09:</strong> Värmlands Bokfestival kl. 10-16, Nöjesfabriken, Karlstad (bokbord). <a href="https://varmlandsbokfestival.se/">Mer information här.</a></p>
        <p>Nu finns boken Bortvald som barn även som ljudbok på <a href="https://www.bookbeat.se/search?title=Bortvald+som+barn&page=1&query=Bortvald+som+barn" aria-label='Lyssna på boken på Bookbeat.' target='_blank' rel="noreferrer">Bookbeat</a>, <a href="https://www.storytel.com/se/books/bortvald-som-barn-2763341" aria-label='Lyssna på boken på Storytel.' target='_blank' rel="noreferrer">Storytel</a> och <a href="https://nextory.com/se/search?q=Bortvald%20Som%20Barn" aria-label='Lyssna på boken på Nextory.' target='_blank' rel="noreferrer">Nextory</a>.</p>
      </aside>
    </div>
  )
}

export default Home